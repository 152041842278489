"use client";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@components/ui/dialog";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import { Icon, IconType } from "../icon/Icon";
import { ILink, ILinkBlock, LinkBlock } from "../linkBlock/LinkBlock";

interface IMobileNavigation {
  navLinks: { link: ILink }[];
  cta: {
    withIcon: boolean;
    icon: IconType;
    link: ILink;
  };
  linksColumns: {
    _key: string;
    title: string;
    links: ILinkBlock[];
  }[];
}

export function MobileNavigation({
  navLinks,
  cta,
  linksColumns,
}: IMobileNavigation) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <nav
        className={`max-w-[calc(100% - 40px)] sticky -top-[100vh] bottom-[100vh] mx-5 flex flex-row items-center justify-between rounded-xl border-primary bg-primary-foreground px-4 py-2 shadow-lg lg:hidden`}
      >
        <Link href="/" className="m-auto" onClick={() => setIsOpen(false)}>
          <Icon iconName="smallLogo" />
        </Link>
        <DialogTrigger
          className="absolute right-4"
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        >
          <Icon iconName="hamburger" />
        </DialogTrigger>

        <DialogContent
          className="flex h-full min-w-full flex-col bg-accent pt-2"
          closeClassName="top-4 right-8"
        >
          <DialogHeader className="items-center pt-4">
            <Icon iconName="smallLogoWhite" />
          </DialogHeader>
          <ul className="-ml-6 flex flex-1 flex-col items-start justify-center gap-10">
            {navLinks.map((i, j) => (
              <li key={j}>
                <LinkBlock
                  link={i.link}
                  variant="Tertiary"
                  size="lg"
                  onClick={() => setIsOpen(false)}
                />
              </li>
            ))}
          </ul>
          <ul className="my-4 flex flex-col gap-4 md:mt-0 md:flex-row">
            {linksColumns.map(({ title, links, _key }) => (
              <li key={_key} className="min-w-[193px] text-sm text-secondary">
                <span className="text-grey-dark">{title}</span>
                <ul className="mt-1 flex flex-col md:mt-5">
                  {links.map((link, i) => (
                    <li key={i} className="-ml-3">
                      <LinkBlock
                        link={{
                          ...link.link,
                        }}
                        variant="Tertiary"
                        size="sm"
                        onClick={() => setIsOpen(false)}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <DialogFooter>
            <LinkBlock link={{ ...cta.link }} onClick={() => setIsOpen(false)}>
              {cta.withIcon && <Icon iconName={cta.icon} />}
            </LinkBlock>
          </DialogFooter>
        </DialogContent>
      </nav>
    </Dialog>
  );
}
